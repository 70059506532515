import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Card,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Drawer,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ArticleIcon from '@mui/icons-material/Article';
import ContactsIcon from '@mui/icons-material/Contacts';
import Carousel from 'react-material-ui-carousel';
import { useTheme, useMediaQuery } from '@mui/material';
import image1 from './images/1.jpg';
import image2 from './images/2.jpg';
import image3 from './images/3.jpg';
import image4 from './images/4.jpg';
import image5 from './images/5.jpg';
import image6 from './images/6.jpg';
import image7 from './images/7.jpeg';
import image8 from './images/8.jpeg';

const App = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleRegisterClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSewjRBrtoNz2gpdilvjlgEkokk-0gJ7bv1ZV6NVsLGz-msRlg/viewform?vc=0&c=0&w=1&flr=0', '_blank');
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              The Wander Wise <span style={{ fontWeight: 'normal', fontSize: '0.8em', color: 'orange' }}>(Beta)</span>
            </Typography>
            {isMobile ? (
                <>
                  <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={toggleDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                      anchor="right"
                      open={drawerOpen}
                      onClose={toggleDrawer(false)}
                  >
                    <List>
                      <ListItem button component="a" href="#community">
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Community" />
                      </ListItem>
                      <ListItem button component="a" href="#trips">
                        <ListItemIcon><TravelExploreIcon /></ListItemIcon>
                        <ListItemText primary="Trips" />
                      </ListItem>
                      <ListItem button component="a" href="#blogs">
                        <ListItemIcon><ArticleIcon /></ListItemIcon>
                        <ListItemText primary="Blogs" />
                      </ListItem>
                      <ListItem button component="a" href="#contact">
                        <ListItemIcon><ContactsIcon /></ListItemIcon>
                        <ListItemText primary="Contact" />
                      </ListItem>
                      <ListItemButton
                          onClick={handleRegisterClick}
                          sx={{ marginLeft: 2 }}
                      >
                        Register
                      </ListItemButton>
                    </List>
                  </Drawer>
                </>
            ) : (
                <>
                  <Button color="inherit" href="#community">Community</Button>
                  <Button color="inherit" href="#trips">Trips</Button>
                  <Button color="inherit" href="#blogs">Blogs</Button>
                  <Button color="inherit" href="#contact">Contact</Button>
                  <Button
                      color="inherit"
                      variant="outlined"
                      sx={{ marginLeft: 2 }}
                      onClick={handleRegisterClick}
                  >
                    Register
                  </Button>
                </>
            )}
          </Toolbar>
        </AppBar>

        <Container sx={{ marginTop: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box textAlign="center" sx={{ padding: 4 }}>
                <Typography variant="h4" fontWeight="bold">
                  Join Us to Create Your Flywheel for Unstoppable Earnings!
                </Typography>
                <Box sx={{ marginTop: 2, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  <Typography sx={{ margin: '0 10px' }}>• Discover</Typography>
                  <Typography sx={{ margin: '0 10px' }}>• Travel</Typography>
                  <Typography sx={{ margin: '0 10px' }}>• Capture</Typography>
                  <Typography sx={{ margin: '0 10px' }}>• Package</Typography>
                  <Typography sx={{ margin: '0 10px' }}>• Publish</Typography>
                  <Typography sx={{ margin: '0 10px' }}>• Earn</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ padding: 4, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                <Box>
                  <Typography variant="h5" fontWeight="bold">Opportunities for Trip Creators !</Typography>
                  <List>
                    <ListItem>
                      <ListItemText primary="• Tools to transform itineraries into unforgettable packages." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Millions of options(hotels, experiences) from leading DMCs." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Personalized storefront to highlight your packages." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Harness your community to boost sales." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Earn on every trip booked through your packages." />
                    </ListItem>
                  </List>
                </Box>

                <Box sx={{ marginTop: 4 }}>
                  <Typography variant="h5" fontWeight="bold">Offerings for Travellers !</Typography>
                  <List>
                    <ListItem>
                      <ListItemText primary="• Stress-free trip planning." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Book experiences from trusted Creators." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• One-stop purchase for entire holiday." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Affordability with our best price guarantee." />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Grid>

            {/* Image Carousel Section */}
            <Grid item xs={12} md={6}>
              <ImageCarousel />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ padding: 4, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                <Typography variant="h5" fontWeight="bold">Why register with us?</Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="• 5L+ Hotels, 1M+ Experiences across 20+ countries." />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="• Add-ons like - Visa, Forex, Insurance." />
                  </ListItem>
                </List>
                <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleRegisterClick}>
                  Register as a Trip Creator
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
  );
};

// Image Carousel Component
const ImageCarousel = () => {
  const categories = [
    {
      title: 'Travel Creators',
      images: [
        image1,
        image2,
        image3,
        image4,
      ],
    },
    {
      title: 'Destinations',
      images: [
        image5,
        image6,
        image7,
        image8,
      ],
    },
  ];

  return (
      <Box sx={{ height: '100%', overflow: 'hidden' }}>
        <Carousel autoPlay={false}>
          {categories.map((category, index) => (
              <Box key={index}>
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                  {category.images.map((image, idx) => (
                      <Grid item xs={6} key={idx}>
                        <Card sx={{ height: '100%' }}>
                          <CardMedia component="img" height="250" image={image} alt={category.title} />
                        </Card>
                      </Grid>
                  ))}
                </Grid>
                <Typography variant="h6" textAlign="left" sx={{ marginTop: 2 }}>{category.title}</Typography>
              </Box>
          ))}
        </Carousel>
      </Box>
  );
};

export default App;
